import React from 'react';
import { SocketTimeoutError } from '../PAGES/classroom-meet/component/utils';

const Win: any = window;

const requestTimeout = 20000;
const requestRetries = 3;

function timeoutCallback(callback: any) {
  let called = false;

  const interval = setTimeout(() => {
    if (called) return;
    called = true;
    callback(new SocketTimeoutError('Request timed out'));
  }, requestTimeout);

  return (...args: any) => {
    if (called) return;
    called = true;
    clearTimeout(interval);

    callback(...args);
  };
}

function _sendRequest(socket: any, type: any, data: any) {
  return new Promise((resolve, reject) => {
    if (!socket) {
      reject('No socket connection');
    } else {
      socket.emit(
        type,
        data,
        timeoutCallback((err: any, response: any) => {
          if (err) reject(err);
          else resolve(response);
        })
      );
    }
  });
}

export const sendRequest: any = async (socket: any, type: any, data: any) => {
  //	console.debug('sendRequest() [method:"%s", data:"%o"]', method, data);
  //

  for (let tries = 0; tries < requestRetries; tries++) {
    try {
      return await _sendRequest(socket, type, data);
    } catch (error) {
      if (error instanceof SocketTimeoutError && tries < requestRetries)
        console.warn('sendRequest() | timeout, retrying [attempt:"%s"]', tries);
      else throw error;
    }
  }
};
