export enum type {
  GET_DOCUMENT_DETAILS = 'GET_DOCUMENT_DETAILS',
  GET_DOCUMENT_DETAILS_ASYNC = 'GET_DOCUMENT_DETAILS_ASYNC',
  GET_DOCUMENT_COMMENT_LIST = 'GET_DOCUMENT_COMMENT_LIST',
  GET_DOCUMENT_COMMENT_LIST_ASYNC = 'GET_DOCUMENT_COMMENT_LIST_ASYNC',
  SHOULD_CALL_COMMENT_LIST = 'SHOULD_CALL_COMMENT_LIST',
  UPDATE_READING_DOCUMENT = 'UPDATE_READING_DOCUMENT',
  UPDATE_READING_DOCUMENT_ASYNC = 'UPDATE_READING_DOCUMENT_ASYNC',
  GET_DOCUMENT_LIST_UPNEXT = 'GET_DOCUMENT_LIST_UPNEXT',
  GET_DOCUMENT_LIST_UPNEXT_ASYNC = 'GET_DOCUMENT_LIST_UPNEXT_ASYNC',
}
