import {
  call,
  takeLatest,
  put,
  delay,
  cancelled,
  takeEvery,
} from 'redux-saga/effects';
import { user } from './types';
import {
  getUserInit,
  userLogout,
  getVideoList,
  getNotificationList,
  getUserPermissionList,
  getOrganizationList,
} from './user-axios';
import Axios from 'axios';
function* watch_getUserInit(action: any) {
  try {
    yield put({
      type: user.USER_INIT_ASYNC,
      is_loading: true,
    });
    const data = yield call(getUserInit, action);
    yield put({
      type: user.USER_INIT_ASYNC,
      data: data.data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_getUserPermissionList(action: any) {
  try {
    yield put({
      type: user.USER_PERMISSIONS_ASYNC,
      is_loading: true,
    });
    const data = yield call(getUserPermissionList, action);
    yield put({
      type: user.USER_PERMISSIONS_ASYNC,
      data: data.data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_userLogout(action: any) {
  try {
    const data = yield call(userLogout, action);
    localStorage.removeItem('user');
    yield delay(1000);
    window.location.href = '/login';
  } catch (error) {
    throw error;
  }
}

function* watch_getVideoList(action: any) {
  // const cancelSource: any = Axios.CancelToken.source();
  // const reqData = Object.assign({}, action, {
  //     cancelToken: cancelSource.token,
  // });
  try {
    yield put({
      type: user.GET_SEARCH_SUGGESTION_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getVideoList, action);
    yield put({
      type: user.GET_SEARCH_SUGGESTION_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    // finally {
    //     if (yield cancelled()) {
    //         cancelSource.cancel('cancelled');
    //     }
    // }
    throw error;
  }
}

function* watch_getNotificationList(action: any) {
  // const cancelSource: any = Axios.CancelToken.source();
  // const reqData = Object.assign({}, action, {
  //     cancelToken: cancelSource.token,
  // });
  try {
    yield put({
      type: user.GET_NOTIFICATION_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getNotificationList, action);
    yield put({
      type: user.GET_NOTIFICATION_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
      total_unread: data.total_unread,
    });
  } catch (error) {
    // finally {
    //     if (yield cancelled()) {
    //         cancelSource.cancel('cancelled');
    //     }
    // }
    throw error;
  }
}

function* watch_getOrganizationList(action: any) {
  // const cancelSource: any = Axios.CancelToken.source();
  // const reqData = Object.assign({}, action, {
  //     cancelToken: cancelSource.token,
  // });
  try {
    yield put({
      type: user.GET_USER_ORGANIZATIONS_ASYNC,
      is_loading: true,
      total_records: 0,
      total_retrieved: 0,
      data: [],
    });
    const data = yield call(getOrganizationList, action);
    yield put({
      type: user.GET_USER_ORGANIZATIONS_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    // finally {
    //     if (yield cancelled()) {
    //         cancelSource.cancel('cancelled');
    //     }
    // }
    throw error;
  }
}

export function* watchUserSagas() {
  yield takeLatest(user.USER_INIT, watch_getUserInit);
  yield takeLatest(user.USER_PERMISSIONS, watch_getUserPermissionList);
  yield takeLatest(user.USER_LOGOUT, watch_userLogout);
  yield takeLatest(user.GET_SEARCH_SUGGESTION_LIST, watch_getVideoList);
  yield takeLatest(user.GET_NOTIFICATION_LIST, watch_getNotificationList);
  yield takeLatest(user.GET_USER_ORGANIZATIONS, watch_getOrganizationList);
}
