import React from 'react';
import { config } from '../../../app.config';
import Axios from 'axios';
import {
  authHeaderAppJson,
  headerAppJson,
} from '../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';

export const getMyContentList = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/my-list`;

    var axiosConfig: any = {
      method: 'get',
      url: url,
      headers: authHeaderAppJson().headers,
      params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const setMyContentDetails = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/details/${action.contentItemType}/${action.contentItemId}/update`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: data,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const deleteMyContentDetails = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/details/${action.contentItemType}/${action.contentItemId}/delete`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: JSON.stringify(data),
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const setMyContentCustomPrivacy = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/custom-privacy/update`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: data,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const addExternalResources = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/external-resource/add`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: data,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};
