import { type } from './type';

const initialState: any = {
  my_list: {
    total_records: 0,
    total_retrieved: 0,
    is_loading: true,
    data: [
      {
        id: 1,
        title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',

        image: {
          medium:
            'https://i.ytimg.com/an_webp/QHBpSxC2Iv0/mqdefault_6s.webp?du=3000&sqp=CMPCw_4F&rs=AOn4CLDOkXEFDV0RNKDJ4JRvbL1-9m8Ndw',
        },
        desc:
          'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 2,
        title: 'Mari Belajar Suku Kata - 3D Animasi (DVD Version)',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/ikRNErwR0pc/mqdefault_6s.webp?du=3000&sqp=CNykw_4F&rs=AOn4CLBTZeKFWa-Qqj_0mdERjS2HyFSIBg',
        },
        desc:
          'Mari Belajar Suku Kata - 3D Animasi (DVD Version) Belajar Mengeja dan Menyebut ! ---------- Sebuah Video Pendidikan Dalam ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Abdul Malik al- Ansori',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Abdul Malik al- Ansori',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 1,
        title:
          'Mari Belajar Mengeja | Dunia Haiwan | Membaca Suku Kata | Bahasa Melayu | Melayu dan English',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/fU9rv5nA1w8/mqdefault_6s.webp?du=3000&sqp=CNWtw_4F&rs=AOn4CLCheIZWx_pNDg9oIwy-3H7V181zbQ',
        },
        desc:
          'Bellaiman : Video ini boleh membantu anak anak mengeja dan membaca sambil mengenali pelbagai binatang dan haiwan.',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Evan You',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Evan You',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 1,
        title:
          'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/XJrvdpNv6NE/mqdefault_6s.webp?du=3000&sqp=CPfCw_4F&rs=AOn4CLCzW7mcb86qeoE_S3uM05-6D-M-pQ',
        },
        desc:
          'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah SK PENGKALAN TENTERA DARAT ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 1,
        title: 'Belajar Baca Berita | SEISMIK Challenge',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/F4pPhtVM7Y4/mqdefault_6s.webp?du=3000&sqp=CNCaw_4F&rs=AOn4CLD4WktBX5JPywqSjBNv4YjS-JSiYg',
        },
        desc:
          'Dalam SEISMIK Challenge kali ini, kitorang dah pun cabar Zaidi, Amin dan Brenda untuk jadi news presenter dalam Bulletin ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 1,
        title: 'Belajar Mengeja | Belajar Membaca | Video Edukasi Anak',
        image: {
          medium:
            'https://i.ytimg.com/vi/DuPLmq4MWUI/hq720.jpg?sqp=-oaymwEZCOgCEMoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBR7x_0E2RoBK_nTK0hqL758gSlCg',
        },
        desc:
          'Belajar mengeja huruf abjad, belajar membaca untuk anak tk lengkap diantaranya: belajar membaca 3 huruf, belajar membaca 5 ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
      {
        id: 1,
        title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/-FPD_b_Zuik/mqdefault_6s.webp?du=3000&sqp=CIG8w_4F&rs=AOn4CLCEyEzy8p4dSsX24X8FnYuERZwJzg',
        },
        desc:
          'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },

      {
        id: 1,
        title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
        image: {
          medium:
            'https://i.ytimg.com/an_webp/DXF6AQ_QS9s/mqdefault_6s.webp?du=3000&sqp=CInJw_4F&rs=AOn4CLDbgGYR_XlFqGNfuIjbEjKoHqLeIg',
        },
        desc:
          'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
        created_at: '2020-12-13T06:09:15.000000Z',
        created_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        modified_at: '2020-12-13T06:09:15.000000Z',
        modified_by: {
          full_name: 'Cikgu Hassan al Hussin',
        },
        total_view: 2232,
        watching_progress: 10,
        duration: '8:35',
      },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GET_MY_DOCUMENT_LIST_ASYNC:
      state.my_list.is_loading = action.is_loading;
      if (action.is_loading == false) {
        state.my_list.data = action.data;
        state.my_list.total_records = action.total_records;
        state.my_list.total_retrieved = action.total_retrieved;
      }
      if (action.status == false) {
        state.my_list.data = [];
      }

      return { ...state };

    default:
      return state;
  }
};
export default todos;
