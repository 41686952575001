export enum type {
  GET_RESOURCE_DETAILS = 'GET_RESOURCE_DETAILS',
  GET_RESOURCE_DETAILS_ASYNC = 'GET_RESOURCE_DETAILS_ASYNC',
  GET_RESOURCE_COMMENT_LIST = 'GET_RESOURCE_COMMENT_LIST',
  GET_RESOURCE_COMMENT_LIST_ASYNC = 'GET_RESOURCE_COMMENT_LIST_ASYNC',
  SHOULD_CALL_COMMENT_LIST = 'SHOULD_CALL_COMMENT_LIST',
  UPDATE_READING_RESOURCE = 'UPDATE_READING_RESOURCE',
  UPDATE_READING_RESOURCE_ASYNC = 'UPDATE_READING_RESOURCE_ASYNC',
  GET_RESOURCE_LIST_UPNEXT = 'GET_RESOURCE_LIST_UPNEXT',
  GET_RESOURCE_LIST_UPNEXT_ASYNC = 'GET_RESOURCE_LIST_UPNEXT_ASYNC',
}
