import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import {
  getVideoDetails,
  getCommentList,
  updateWatchingVideo,
  getVideoListUPNEXT,
} from './axios';

function* watch_getVideoDetails(action: any) {
  try {
    yield put({
      type: type.GET_VIDEO_DETAILS_ASYNC,
      is_loading: true,
    });
    const data = yield call(getVideoDetails, action);
    yield put({
      type: type.GET_VIDEO_DETAILS_ASYNC,
      data: data.data,
      is_loading: false,
      status: data.status,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_getCommentList(action: any) {
  try {
    yield put({
      type: type.GET_VIDEO_COMMENT_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getCommentList, action);
    yield put({
      type: type.GET_VIDEO_COMMENT_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_updateWatchingVideo(action: any) {
  try {
    yield put({
      type: type.UPDATE_WATCHING_VIDEO_ASYNC,
      is_loading: true,
    });
    const data = yield call(updateWatchingVideo, action);
    yield put({
      type: type.UPDATE_WATCHING_VIDEO_ASYNC,

      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_getVideoListUPNEXT(action: any) {
  try {
    yield put({
      type: type.GET_VIDEO_LIST_UPNEXT_ASYNC,
      is_loading: true,
    });
    const data = yield call(getVideoListUPNEXT, action);
    yield put({
      type: type.GET_VIDEO_LIST_UPNEXT_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    throw error;
  }
}

export function* watch__VideoDetailsSagas() {
  yield takeLatest(type.GET_VIDEO_DETAILS, watch_getVideoDetails);
  yield takeLatest(type.GET_VIDEO_COMMENT_LIST, watch_getCommentList);
  yield takeLatest(type.UPDATE_WATCHING_VIDEO, watch_updateWatchingVideo);
  yield takeLatest(type.GET_VIDEO_LIST_UPNEXT, watch_getVideoListUPNEXT);
}
