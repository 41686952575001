import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getContentList } from './axios';

function* watch_getContentList(action: any) {
  try {
    yield put({
      type: type.GET_CONTENT_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getContentList, action);
    yield put({
      type: type.GET_CONTENT_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    throw error;
  }
}

export function* watch__LibrarySagas() {
  yield takeLatest(type.GET_CONTENT_LIST, watch_getContentList);
}
