import React from 'react';
import { config } from '../../../app.config';
import Axios from 'axios';
import {
  authHeaderAppJson,
  headerAppJson,
} from '../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';

export const getFeaturedContents = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(`${config.SERVER_ENDPOINT}/contents/featured-list`, {
      ...authHeaderAppJson(),
      params: { ...action.params },
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};

export const getContributorList = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(`${config.SERVER_ENDPOINT}/contents/contributor-list`, {
      ...authHeaderAppJson(),
      params: { ...action.params },
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};

export const getMyLearningRecommended = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${config.SERVER_ENDPOINT}/user/my-learning-recommended/contents`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};

export const getContentList = (action: any) => {
  if (!action.params.filters) {
    action.params.filters = {};
  }
  action.params.filters.category_enclude = '5';

  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/list`;

    var axiosConfig: any = {
      method: 'get',
      url: url,
      headers: action.is_login
        ? authHeaderAppJson().headers
        : headerAppJson().headers,
      params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};

export const setMyContentDetails = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/details/${action.contentsId}/update`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: data,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};

export const deleteMyContentDetails = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/${action.contentsId}/delete`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: JSON.stringify(data),
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });

        throw err;
      });
  });
};

export const setMyContentCustomPrivacy = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      ...action.data,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/custom-privacy/update`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: data,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: getErrorMessageFromAxios(err) });
        throw err;
      });
  });
};
