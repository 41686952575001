import React from 'react';
import { config as app } from '../../../app.config';
import Axios from 'axios';
import { authHeaderAppJson } from '../../../SERVICES/axios-header';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';
export const getUserClasses = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(`${app.SERVER_ENDPOINT}/user/my-classes`, {
      ...authHeaderAppJson(),
      params: { ...action.params },
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        throw err;
      });
  });
};
export const getUserClass = (action: any) => {
  return Axios.get(
    `${app.SERVER_ENDPOINT}/user/my-class/${action.class_code}`,
    {
      ...authHeaderAppJson(),
      params: { ...action.params },
    }
  )
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      // return({ status: false, message: `${getErrorMessageFromAxios(err)}` });
      throw err;
    });
};

export const getClassMembers = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(`${app.SERVER_ENDPOINT}/class/members/list/${action.class_id}`, {
      ...authHeaderAppJson(),
      params: { ...action.params },
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const deleteClassMember = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/members/delete/${action.class_id}/${action.member_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        console.log(err);
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const leaveClass = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/members/leave/${action.class_id}/${action.member_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const addComment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    // const params = {
    //   comment: action.params.comment,
    //   comment_level: action.params.comment_level,
    //   parent_id: action.params.reply_id,
    // };

    const url = `${app.SERVER_ENDPOINT}/contents/comment/${action.content_id}/add`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const updateComment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/contents/comment/${action.comment_id}/update`;

    var axiosConfig: any = {
      method: 'put',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const addGoalMastery = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/add-course-mastery-goal/${action.class_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const editGoalMastery = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/set-course-mastery-goal/${action.course_mastery_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const deleteGoalMastery = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/delete-course-mastery-goal/${action.course_mastery_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const listGoalMastery = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/list-course-mastery-goal/${action.class_id}`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const assignCourseContent = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/assign-course-contents/${action.class_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const listCourseAssignment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/list-course-assignment/${action.class_id}`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const editCourseAssignment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/edit-course-assignment/${action.assignment_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const deleteCourseAssignment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/delete-course-assignment/${action.class_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const editCourseAssignmentDuration = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${app.SERVER_ENDPOINT}/class/coursework/edit-course-assignment-duration/${action.class_id}`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const listCourseAssignmentForStudent = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/list-course-assignment-student`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const courseMasteryProgress = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/course-mastery-progress/${action.course_mastery_id}`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const courseMasteryProgressBySection = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/course-mastery-progress-section/${action.course_mastery_id}/${action.course_section_id}`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};

export const courseMasteryProgressByLesson = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    Axios.get(
      `${app.SERVER_ENDPOINT}/class/coursework/course-mastery-progress-lesson/${action.course_mastery_id}/${action.course_lesson_id}`,
      {
        ...authHeaderAppJson(),
        params: { ...action.params },
      }
    )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        resolve({ status: false, message: `${getErrorMessageFromAxios(err)}` });
        throw err;
      });
  });
};
