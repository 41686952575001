import { types as classroom } from './types';

const initialState = {
  my_classes: {
    is_loading: true,
    data: [
      {
        value: 108,
        label: 'Dinamik (1st period)',
        class_code: 'GEHFMXJ',
      },
      { value: 186, label: 'UPSR', class_code: 'M8GAYNE' },
    ],
  },
  my_class: {
    is_loading: true,
    data: {
      value: 108,
      label: 'Dinamik (1st period)',
      class_code: 'GEHFMXJ',

      created_by: 43155,
      created_date: '2018-10-11T23:31:35.000Z',
    },
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case classroom.MY_CLASS_LIST_ASYNC:
      if (!action.is_loading) {
        state.my_classes = action.data;
      }
      state.my_classes.is_loading = action.is_loading;

      return { ...state };
    case classroom.MY_CLASS_ASYNC:
      if (!action.is_loading) {
        state.my_class = action.data;
      }
      state.my_class.is_loading = action.is_loading;

      return { ...state };
    default:
      return state;
  }
};
export default todos;
