import { drawer } from './types';

const initialState = {
  levels: {
    data: [
      {
        value: 'spm',
        label: 'SPM',
      },
      {
        value: 'stpm',
        label: 'STPM',
      },
    ],
  },
  subjects: {
    data: [
      {
        label: 'Bahasa Melayu',
        value: 'bm',
      },
      {
        label: (t?: any) => t('filter.bi'),
        value: 'bi',
      },
      {
        label: (t?: any) => t('filter.science'),
        value: 'science',
      },
      {
        label: (t?: any) => t('filter.math'),
        value: 'math',
      },
    ],
  },
  upload_date: {
    data: [
      {
        label: (t?: any) => t('filter.last_hour'),
        value: 'lhr',
      },
      {
        label: (t?: any) => t('filter.today'),
        value: 'tdy',
      },
      {
        label: (t?: any) => t('filter.this_week'),
        value: 'twk',
      },
      {
        label: (t?: any) => t('filter.this_month'),
        value: 'tmh',
      },
      {
        label: (t?: any) => t('filter.this_year'),
        value: 'tyr',
      },
    ],
  },
  durations: {
    data: [
      {
        label: (t?: any) => t('filter.short'),
        value: 'shr',
      },
      {
        label: (t?: any) => t('filter.long'),
        value: 'log',
      },
    ],
  },
  genders: {
    data: [
      {
        label: (t?: any) => t('male'),
        value: 1,
      },
      {
        label: (t?: any) => t('female'),
        value: 2,
      },
    ],
  },
  roles: {
    data: [
      {
        label: (t?: any) => t('role.student'),
        value: 2,
      },
      {
        label: (t?: any) => t('role.teacher'),
        value: 1,
      },
      {
        label: (t?: any) => t('role.parent'),
        value: 3,
      },
      {
        label: (t?: any) => t('role.moderator'),
        value: 6,
      },
      {
        label: (t?: any) => t('role.admin'),
        value: 4,
      },
      {
        label: (t?: any) => t('role.superadmin'),
        value: 5,
      },
    ],
  },
  general_roles: {
    data: [
      {
        label: (t?: any) => t('learners'),
        value: 2,
      },
      {
        label: (t?: any) => t('teachers'),
        value: 1,
      },
      {
        label: (t?: any) => t('parents'),
        value: 3,
      },
    ],
  },
  homepages: {
    data: [
      {
        label: (t?: any) => t('learner'),
        value: 2,
      },
      {
        label: (t?: any) => t('label.teacher_dashboard'),
        value: 1,
      },
      {
        label: (t?: any) => t('label.parent_dashboard'),
        value: 3,
      },
    ],
  },
  watching_progress: {
    data: [
      {
        label: (t?: any) => t('filter.not_started'),
        value: 'not_started',
      },
      {
        label: (t?: any) => t('filter.in_progress'),
        value: 'in_progress',
      },
      {
        label: (t?: any) => t('filter.completed'),
        value: 'completed',
      },
    ],
  },
  sort_by: {
    data: [
      {
        label: (t?: any) => t('filter.title_a_to_z'),
        value: 'atz',
      },
      {
        label: (t?: any) => t('filter.title_z_to_a'),
        value: 'zta',
      },
      {
        label: (t?: any) => t('sort_by.newest_first'),
        value: 'new',
      },
      {
        label: (t?: any) => t('sort.recently_update'),
        value: 'mod',
      },
    ],
  },
  sort_assignments_by: {
    data: [
      {
        label: (t?: any) => t('sort_by.newest_first'),
        value: 'new',
      },
      {
        label: (t?: any) => t('sort.recently_update'),
        value: 'mod',
      },
    ],
  },
  sort_users_by: {
    data: [
      {
        label: (t?: any) => t('filter.name_a_to_z'),
        value: 'atz',
      },
      {
        label: (t?: any) => t('filter.name_z_to_a'),
        value: 'zta',
      },
      {
        label: (t?: any) => t('sort_by.newest_first'),
        value: 'new',
      },
      {
        label: (t?: any) => t('sort.recently_update'),
        value: 'mod',
      },
      // {
      //     label: (t?: any) => t('filter.relevance'),
      //     value: 'rlc',
      // },
      //   {
      //       label: (t?: any) => t('filter.upload_date'),
      //       value: 'upd',
      //   },
      // {
      //     label: (t?: any) => t('filter.view_count'),
      //     value: 'vct',
      // },
      // {
      //     label: (t?: any) => t('filter.rating'),
      //     value: 'rtg',
      // },
    ],
  },
  // sort_audit_trail_by: {
  //   data: [

  //     {
  //       label: (t?: any) => t('sort_by.newest_first'),
  //       value: 'new',
  //     },
  //     {
  //       label: (t?: any) => t('sort.recently_update'),
  //       value: 'ctd',
  //     },
  //     // {
  //     //     label: (t?: any) => t('filter.relevance'),
  //     //     value: 'rlc',
  //     // },
  //     //   {
  //     //       label: (t?: any) => t('filter.upload_date'),
  //     //       value: 'upd',
  //     //   },
  //     // {
  //     //     label: (t?: any) => t('filter.view_count'),
  //     //     value: 'vct',
  //     // },
  //     // {
  //     //     label: (t?: any) => t('filter.rating'),
  //     //     value: 'rtg',
  //     // },
  //   ],
  // },

  content_learn: {
    data: [
      {
        label: (t?: any) => t('filter.video'),
        value: '1',
      },
      {
        label: (t?: any) => t('filter.document'),
        value: '2',
      },
      {
        label: (t?: any) => t('filter.external_resource'),
        value: '4',
      },
    ],
  },
  content_lesson: {
    data: [
      {
        label: (t?: any) => t('module.articles'),
        value: '9',
      },
      {
        label: (t?: any) => t('videos'),
        value: '1',
      },
      {
        label: (t?: any) => t('documents'),
        value: '2',
      },
      {
        label: (t?: any) => t('external-resources'),
        value: '4',
      },
      {
        label: (t?: any) => t('module.practices'),
        value: '7',
      },
    ],
  },

  content_categories: {
    data: [
      {
        label: (t?: any) => t('module.articles'),
        value: '9',
      },
      {
        label: (t?: any) => t('videos'),
        value: '1',
      },
      {
        label: (t?: any) => t('documents'),
        value: '2',
      },
      {
        label: (t?: any) => t('external-resources'),
        value: '4',
      },

      {
        label: (t?: any) => t('module.courses'),
        value: '6',
      },
      {
        label: (t?: any) => t('module.practices'),
        value: '7',
      },
      // {
      //   label: (t?: any) => t('quiz'),
      //   value: '8',
      // },
    ],
  },
  admin_content_categories: {
    data: [
      {
        label: (t?: any) => t('module.articles'),
        value: '9',
      },
      {
        label: (t?: any) => t('videos'),
        value: '1',
      },
      {
        label: (t?: any) => t('documents'),
        value: '2',
      },
      {
        label: (t?: any) => t('external-resources'),
        value: '4',
      },
      {
        label: (t?: any) => t('module.questions'),
        value: '5',
      },
      {
        label: (t?: any) => t('module.courses'),
        value: '6',
      },
      {
        label: (t?: any) => t('module.practices'),
        value: '7',
      },
      // {
      //   label: (t?: any) => t('quiz'),
      //   value: '8',
      // },
    ],
  },
  content_visibilities: {
    data: [
      {
        label: (t?: any) => t('filter.unlisted'),
        value: '1',
      },
      {
        label: (t?: any) => t('filter.only_me'),
        value: '2',
      },
      {
        label: (t?: any) => t('filter.public'),
        value: '3',
      },
      {
        label: (t?: any) => t('filter.members'),
        value: '4',
      },
      {
        label: (t?: any) => t('filter.custom'),
        value: '5',
      },
    ],
  },
  content_channels: {
    data: [
      {
        label: (t?: any) => t('filter.upload'),
        value: '1',
      },
      // {
      //   label: (t?: any) => t('filter.live'),
      //   value: '2',
      // },
      {
        label: (t?: any) => t('filter.record'),
        value: '3',
      },
      {
        label: (t?: any) => t('filter.youtube'),
        value: '5',
      },
      // {
      //   label: (t?: any) => t('filter.unknown'),
      //   value: '4',
      // },
    ],
  },
  languages: {
    data: [
      {
        label: 'Bahasa Melayu',
        value: 'my',
      },

      {
        label: 'English',
        value: 'en',
      },
    ],
  },
  content_status: {
    data: [
      {
        label: (t?: any) => t('label.active'),
        value: true,
      },
      {
        label: (t?: any) => t('label.inactive'),
        value: false,
      },
    ],
  },
  presence_status: {
    data: [
      {
        label: (t?: any) => t('label.online'),
        value: true,
      },
      {
        label: (t?: any) => t('label.offline'),
        value: false,
      },
    ],
  },
  users_date: {
    data: [
      {
        label: (t?: any) => t('label.registered_date'),
        value: 'created',
      },
      {
        label: (t?: any) => t('label.last_online'),
        value: 'last_online',
      },
    ],
  },
  assignments_date: {
    data: [
      {
        label: (t?: any) => t('label.created_date'),
        value: 'created',
      },
      {
        label: (t?: any) => t('label.start_date'),
        value: 'start',
      },
      {
        label: (t?: any) => t('label.due_date'),
        value: 'due',
      },
    ],
  },
  question_set_type: {
    data: [
      {
        label: (t?: any) => t('label.question_set_different'),
        sublabel: (t?: any) => t('sublabel.question_set_different'),
        value: 'different',
      },
      {
        label: (t?: any) => t('label.question_set_same'),
        sublabel: (t?: any) => t('sublabel.question_set_same'),
        value: 'same',
      },
    ],
  },
  ratings_tabs: {
    data: [
      {
        label: (t?: any) => t('submodule.all'),
        value: 'all',
      },
      {
        label: (t?: any) => t('label.5star'),
        value: '5',
      },
      {
        label: (t?: any) => t('label.4star'),
        value: '4',
      },
      {
        label: (t?: any) => t('label.3star'),
        value: '3',
      },
      {
        label: (t?: any) => t('label.2star'),
        value: '2',
      },
      {
        label: (t?: any) => t('label.1star'),
        value: '1',
      },
    ],
  },
  selected_subjects: {
    data: [
      {
        label: (lang: any) => (lang == 'my' ? 'Sains Data' : 'Data Science'),
        value: 'bm',
      },
      {
        label: (lang: any) => (lang == 'my' ? 'Perniagaan' : 'Business'),
        value: 'bi',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Sains Komputer' : 'Computer Sciences',
        value: 'science',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Teknologi maklumat' : 'Information Technology',
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Belajar bahasa' : 'Language Learning',
        value: 'math',
      },
      {
        label: (lang: any) => (lang == 'my' ? 'Kesihatan' : 'Health'),
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Perkembangan Diri' : 'Personal Development',
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my'
            ? 'Sains Fizikal dan Kejuruteraan'
            : 'Pysical Science and Engineering',
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Sains Sosial' : 'Social Sciences',
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Seni dan Kemanusiaan' : 'Arts and Humanities',
        value: 'math',
      },
      {
        label: (lang: any) =>
          lang == 'my' ? 'Matematik dan Logik' : 'Math and Logics',
        value: 'math',
      },
    ],
  },
  organizations_type: {
    data: [
      {
        label: (t?: any) => t('organization'),
        value: 1,
      },
      {
        label: (t?: any) => t('group'),
        value: 2,
      },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default todos;
