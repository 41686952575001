export enum type {
  GET_VIDEO_DETAILS = 'GET_VIDEO_DETAILS',
  GET_VIDEO_DETAILS_ASYNC = 'GET_VIDEO_DETAILS_ASYNC',
  GET_VIDEO_COMMENT_LIST = 'GET_VIDEO_COMMENT_LIST',
  GET_VIDEO_COMMENT_LIST_ASYNC = 'GET_VIDEO_COMMENT_LIST_ASYNC',
  SHOULD_CALL_COMMENT_LIST = 'SHOULD_CALL_COMMENT_LIST',
  UPDATE_WATCHING_VIDEO = 'UPDATE_WATCHING_VIDEO',
  UPDATE_WATCHING_VIDEO_ASYNC = 'UPDATE_WATCHING_VIDEO_ASYNC',
  GET_VIDEO_LIST_UPNEXT = 'GET_VIDEO_LIST_UPNEXT',
  GET_VIDEO_LIST_UPNEXT_ASYNC = 'GET_VIDEO_LIST_UPNEXT_ASYNC',
}
