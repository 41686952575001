import { drawer } from './types';

const initialState = {
  status: false, // collape expand
  sec_status: false,
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case drawer.DRAWER_TOOGLE:
      state.status = action.status;
      return { ...state };
    case drawer.SEC_DRAWER_TOOGLE:
      state.sec_status = action.status;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
