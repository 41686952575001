import { all } from 'redux-saga/effects';

import { watchUserSagas } from './user-saga';
import { watch__ClassroomAll } from '../PAGES/my-classroom/store/saga';
import { watch__LibrarySagas } from '../PAGES/library/store/saga';
import { watch__StudioSagas } from '../PAGES_INSTRUCTOR/my-videos/store/saga';
import { watch__MyDocumentSagas } from '../PAGES_INSTRUCTOR/my-documents/store/saga';
import { watch__VideoDetailsSagas } from '../PAGES/video-watch/store/saga';
import { watch__DocumentDetailsSagas } from '../PAGES/document-read/store/saga';
import { watch__MyExternalResourceSagas } from '../PAGES_INSTRUCTOR/my-external-resources/store/saga';
import { watch__ResourceDetailsSagas } from '../PAGES/external-resource_view/store/saga';

export default function* rootSaga() {
  yield all([
    watchUserSagas(),
    watch__ClassroomAll(),
    watch__LibrarySagas(),
    watch__VideoDetailsSagas(),
    watch__StudioSagas(),
    watch__MyDocumentSagas(),
    watch__DocumentDetailsSagas(),
    watch__MyExternalResourceSagas(),
    watch__ResourceDetailsSagas(),
  ]);
}
