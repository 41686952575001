import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getMyContentList } from './axios';

function* watch_getMyContentList(action: any) {
  try {
    yield put({
      type: type.GET_MY_EXTERNAL_RESOURCE_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getMyContentList, action);
    yield put({
      type: type.GET_MY_EXTERNAL_RESOURCE_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    throw error;
  }
}

export function* watch__MyExternalResourceSagas() {
  yield takeLatest(type.GET_MY_EXTERNAL_RESOURCE_LIST, watch_getMyContentList);
}
