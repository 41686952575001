import React from 'react';
import { config } from '../../../app.config';
import Axios from 'axios';
import {
  authHeaderAppJson,
  headerAppJson,
} from '../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';

// export const getVideoList = (action: any) => {
//     return Axios.get(`${config.SERVER_ENDPOINT}/video/list`, {
//         ...authHeaderAppJson(),
//         params: { ...action.params },
//     })
//         .then((resp) => resp.data)
//         .catch((err) => {
//             getErrorMessageFromAxios(err);
//             throw err;
//         });
// };

export const getVideoDetails = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    // const params = {
    //   ...action.params,
    // };

    const url = `${config.SERVER_ENDPOINT}/contents/details/${action.contentItemType}/${action.contentItemId}`;

    var axiosConfig: any = {
      method: 'get',
      url: url,
      headers: authHeaderAppJson().headers,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const getCommentList = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
      sort: action.sortBy ? action.sortBy : 'desc',
    };

    const url = `${config.SERVER_ENDPOINT}/contents/comment/${action.content_id}/list`;

    var axiosConfig: any = {
      method: 'get',
      url: url,
      headers: authHeaderAppJson().headers,
      params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const addComment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    // const params = {
    //   comment: action.params.comment,
    //   comment_level: action.params.comment_level,
    //   parent_id: action.params.reply_id,
    // };

    const url = `${config.SERVER_ENDPOINT}/contents/comment/${action.content_id}/add`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const deleteComment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/comment/${action.comment_id}/delete`;

    var axiosConfig: any = {
      method: 'post',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const updateComment = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/comment/${action.comment_id}/update`;

    var axiosConfig: any = {
      method: 'put',
      url: url,
      headers: authHeaderAppJson().headers,
      data: params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const updateContentLearner = (action: any) => {
  const params = {
    ...action.params,
  };

  const url = `${config.SERVER_ENDPOINT}/contents/content-learner/${action.content_item_type}/${action.content_learner_id}/update`;

  var axiosConfig: any = {
    method: 'put',
    url: url,
    headers: authHeaderAppJson().headers,
    data: params,
  };
  return Axios(axiosConfig)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      getErrorMessageFromAxios(err);
      throw err;
    });
};

export const getVideoListUPNEXT = (action: any) => {
  return new Promise((resolve: any, reject: any) => {
    const params = {
      ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/contents/upnext/${action.excludeContentId}/list`;

    var axiosConfig: any = {
      method: 'get',
      url: url,
      headers: action.is_login
        ? authHeaderAppJson().headers
        : headerAppJson().headers,
      params,
    };
    return Axios(axiosConfig)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};
