import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MainWrapper from '../CONTAINERS/layouts/index-wrapper';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import '../i18n';
import { PrivateRoute } from './private-route';
import { CheckPrivateRoute } from './check-private-route';

//Portal Pages
const PortalWelcome = lazy(() => import('../PAGES_PORTAL/welcome/index'));
const XcelearnPlus = lazy(() => import('../PAGES_PORTAL/xcelearn-plus/index'));
const TermsCondition = lazy(
  () => import('../PAGES_PORTAL/terms-and-conditions/index')
);

const Donate = lazy(() => import('../PAGES/donate/index'));

const PrivacyPolicy = lazy(() => import('../PAGES/privacy-policy/index'));
const Logout = lazy(() => import('../PAGES/logout/index'));
const Login = lazy(() => import('../PAGES/login/index'));
const Register = lazy(() => import('../PAGES/register/index'));
const ForgotPassword = lazy(() => import('../PAGES/forgot-password/index'));
const ResetPassword = lazy(
  () => import('../PAGES/forgot-reset-password/index')
);
const User = lazy(() => import('../PAGES/user/index'));
const Redirect = lazy(() => import('../PAGES/redirect/index'));
const Welcome = lazy(() => import('../PAGES/welcome/index'));
const DashboardParent = lazy(() => import('../PAGES/dashboard-parent/index'));
const DashboardTeacher = lazy(() => import('../PAGES/dashboard-teacher/index'));
const Classroom = lazy(() => import('../PAGES/classroom/index'));
const MyClassroom = lazy(() => import('../PAGES/my-classroom/index'));
const MyClassroomScoreDetails = lazy(
  () => import('../PAGES/my-classroom-score-details/index')
);

const MarketPlace = lazy(() => import('../PAGES/marketplace/index'));
const MyClassroomMeet = lazy(() => import('../PAGES/classroom-meet/index'));
const Recording = lazy(() => import('../PAGES/recording/index'));
const Library = lazy(() => import('../PAGES/library/index'));
const Courses = lazy(() => import('../PAGES/courses/index'));
const CourseView = lazy(() => import('../PAGES/course-view/index'));
const CourseLessonsView = lazy(
  () => import('../PAGES/course-lessons-view/index')
);

//  Shopping Cart
const Cart = lazy(() => import('../PAGES/cart/index'));

const MainSearchResult = lazy(
  () => import('../PAGES/main-search-result/index')
);

const CourseLessonsLearnMode = lazy(
  () => import('../PAGES/course-lessons-learn-mode/index')
);
const VideoWatch = lazy(() => import('../PAGES/video-watch/index'));
const DocumentRead = lazy(() => import('../PAGES/document-read/index'));
const ArticleRead = lazy(() => import('../PAGES/article-read/index'));
const QuestionAnswer = lazy(() => import('../PAGES/question-answer/index'));
const PracticeAnswer = lazy(() => import('../PAGES/practice-answer/index'));
const MyVideos = lazy(() => import('../PAGES_INSTRUCTOR/my-videos/index'));
const MyPDF = lazy(() => import('../PAGES_INSTRUCTOR/my-documents/index'));
const myExternalResources = lazy(
  () => import('../PAGES_INSTRUCTOR/my-external-resources/index')
);
const externalResourceView = lazy(
  () => import('../PAGES/external-resource_view/index')
);

const organizationGroupDetails = lazy(() => import('../PAGES/my-space/index'));
const NotFoundPage = lazy(() => import('../PAGES/404/index'));

//ADMIN PAGES
const Admin_ContentsResources = lazy(
  () => import('../PAGES_ADMIN/contents_resources/index')
);

const Admin_Users = lazy(() => import('../PAGES_ADMIN/users/index'));
const Admin_Auditrails = lazy(
  () => import('../PAGES_ADMIN/audit-trails/index')
);
const Admin_Roles = lazy(() => import('../PAGES_ADMIN/roles/index'));
const Admin_Subjects = lazy(() => import('../PAGES_ADMIN/subjects/index'));
const Admin_Topics = lazy(() => import('../PAGES_ADMIN/topics/index'));
const Admin_Organizations = lazy(
  () => import('../PAGES_ADMIN/organizations/index')
);
const Admin_OrganizationDetails = lazy(
  () => import('../PAGES_ADMIN/organization-details/index')
);
const Admin_OrganizationGroupDetails = lazy(
  () => import('../PAGES_ADMIN/organization-group-details/index')
);

const Admin_SubjectDetails = lazy(
  () => import('../PAGES_ADMIN/subject-details/index')
);
const Admin_TopicDetails = lazy(
  () => import('../PAGES_ADMIN/topic-details/index')
);
// PAGES_SUPPORT
const SupportCategories = lazy(
  () => import('../PAGES_SUPPORT/categories/index')
);

const SupportCategoryDetails = lazy(
  () => import('../PAGES_SUPPORT/category-details/index')
);

const SupportArticleDetails = lazy(
  () => import('../PAGES_SUPPORT/article-manager/index')
);

const SupportArticles = lazy(() => import('../PAGES_SUPPORT/articles/index'));

const SupportHelp = lazy(() => import('../PAGES_SUPPORT/support-home/index'));

const SupportSection = lazy(
  () => import('../PAGES_SUPPORT/support-section/index')
);

const SupportArticle = lazy(
  () => import('../PAGES_SUPPORT/support-articles/index')
);

//SUPPORT HELP FOR ENDUSER
const Help_MainPage = lazy(
  () => import('../PAGES_SUPPORT_HELP/help-main-page/index')
);

const Help_articleRead = lazy(
  () => import('../PAGES_SUPPORT_HELP/help-article-read/index')
);

//DUMMY PAGES
const Dummy_Question = lazy(() => import('../PAGES_DUMMY/question/index'));

//INSTRUCTOR
const Instructor_QuestionManage = lazy(
  () => import('../PAGES_INSTRUCTOR/question-manager/index')
);

const myQuestions = lazy(
  () => import('../PAGES_INSTRUCTOR/my-questions/index')
);

const myArticles = lazy(() => import('../PAGES_INSTRUCTOR/my-articles/index'));

const Instructor_ArticleManage = lazy(
  () => import('../PAGES_INSTRUCTOR/article-manager/index')
);

const myCourses = lazy(() => import('../PAGES_INSTRUCTOR/my-courses/index'));

const Instructor_CourseManage = lazy(
  () => import('../PAGES_INSTRUCTOR/course-manager/index')
);

const Instructor_CourseManageCurriculum = lazy(
  () => import('../PAGES_INSTRUCTOR/course-manage-curriculum/index')
);

const Instructor_PracticeManage = lazy(
  () => import('../PAGES_INSTRUCTOR/practice-manager/index')
);

const Instructor_MyPractice = lazy(
  () => import('../PAGES_INSTRUCTOR/my-practice/index')
);

const Instructor_VideoManage = lazy(
  () => import('../PAGES_INSTRUCTOR/video-manager/index')
);

const Instructor_DocumentManage = lazy(
  () => import('../PAGES_INSTRUCTOR/document-manager/index')
);

const Instructor_externalResourceManage = lazy(
  () => import('../PAGES_INSTRUCTOR/external-resource-manager/index')
);
function MainRouter() {
  return (
    <MainWrapper>
      <Suspense
        fallback={
          <div className='loading-wrapper'>
            <Grid item xs={8} sm={5}>
              <Skeleton height={200} />
              <Skeleton height={50} />
              <Skeleton height={50} animation={false} />
              <Skeleton height={50} animation='wave' />
            </Grid>
          </div>
        }
      >
        <Switch>
          <CheckPrivateRoute exact path='/' component={PortalWelcome} />
          <Route exact path='/xcelearn-plus' component={XcelearnPlus} />
          <Route exact path='/home-learning/:view?' component={Welcome} />
          <Route exact path='/donate/:view?' component={Donate} />
          <Route
            exact
            path='/dashboard-parent/:view?'
            component={DashboardParent}
          />
          <Route
            exact
            path='/dashboard-teacher/:view?'
            component={DashboardTeacher}
          />
          <Route
            exact
            path='/redirect/:token/:class_code'
            component={Redirect}
          />
          <Route exact path='/cart/:view?' component={Cart} />
          <Route
            exact
            path='/search-results/:view/:keyword'
            component={MainSearchResult}
          />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/terms-and-condition' component={TermsCondition} />

          <Route exact path='/support/' component={SupportHelp} />
          <Route
            exact
            path='/support/article/:article_slug'
            component={SupportArticle}
          />
          <Route
            exact
            path='/support/section/:section_slug'
            component={SupportSection}
          />
          <Route
            exact
            path='/support/help/:view?/:keyword?'
            component={Help_MainPage}
          />
          <Route
            exact
            path='/help/article/:article_slug'
            component={Help_articleRead}
          />
          <PrivateRoute exact path='/logout' component={Logout} />
          <CheckPrivateRoute
            exact
            path='/forgot-password'
            component={ForgotPassword}
          />
          <CheckPrivateRoute
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <PrivateRoute
            exact
            path='/class/:class_id/:view_id?/:view_2?'
            component={MyClassroom}
          />
          <PrivateRoute
            exact
            path='/coach/report/:view_id/:assignment_id'
            component={MyClassroomScoreDetails}
          />
          <PrivateRoute exact path='/classroom/:view?' component={Classroom} />
          <PrivateRoute
            exact
            path='/room/:room_id'
            component={MyClassroomMeet}
          />
          <Route
            exact
            path='/library/:view_id?/:keyword?'
            component={Library}
          />
          <Route
            exact
            path='/marketplace/:view_id?/:keyword?'
            component={MarketPlace}
          />
          <Route
            exact
            path='/courses/:view_id?/:keyword?'
            component={Courses}
          />
          <Route
            exact
            path='/course/:course_slug/:view?'
            component={CourseView}
          />
          <PrivateRoute
            exact
            path='/course/:course_slug/curriculum/:curriculum_slug/:lesson_slug?'
            component={CourseLessonsView}
          />
          <PrivateRoute
            exact
            path='/course/:course_slug/lesson/:curriculum_slug/:lesson_slug/:content_id?'
            component={CourseLessonsLearnMode}
          />
          <PrivateRoute
            exact
            path='/my-videos/:view_id?/:keyword?'
            component={MyVideos}
          />
          <PrivateRoute
            exact
            path='/my-documents/:view_id?/:keyword?'
            component={MyPDF}
          />
          <PrivateRoute
            exact
            path='/my-external-resources/:view_id?/:keyword?'
            component={myExternalResources}
          />
          <PrivateRoute
            exact
            path='/my-articles/:view_id?/:keyword?'
            component={myArticles}
          />
          <PrivateRoute
            exact
            path='/my-questions/:view_id?/:keyword?'
            component={myQuestions}
          />
          <PrivateRoute
            exact
            path='/my-practice/:view_id?/:keyword?'
            component={Instructor_MyPractice}
          />
          <PrivateRoute
            exact
            path='/my-courses/:view_id?/:keyword?'
            component={myCourses}
          />
          <Route exact path='/watch/:video_slug' component={VideoWatch} />
          <Route exact path='/learn/:article_slug' component={ArticleRead} />
          <Route exact path='/read/:document_slug' component={DocumentRead} />
          <Route
            exact
            path='/answer/:question_slug'
            component={QuestionAnswer}
          />
          <Route
            exact
            path='/practice/:practice_slug'
            component={PracticeAnswer}
          />
          <Route
            exact
            path='/view/:resource_slug'
            component={externalResourceView}
          />
          <PrivateRoute exact path='/recording' component={Recording} />
          <CheckPrivateRoute exact path='/login' component={Login} />
          <CheckPrivateRoute
            exact
            path='/register/:role?'
            component={Register}
          />
          <PrivateRoute
            exact
            path='/user/:view?/:user_slug?'
            component={User}
          />

          <PrivateRoute
            exact
            path='/sapot/categories'
            component={SupportCategories}
          />
          <PrivateRoute
            exact
            path='/sapot/category/:category_slug/:view?'
            component={SupportCategoryDetails}
          />
          <PrivateRoute
            exact
            path='/sapot/articles'
            component={SupportArticles}
          />
          <PrivateRoute
            exact
            path='/sapot/article/:article_slug/:view?'
            component={SupportArticleDetails}
          />
          <PrivateRoute
            exact
            path='/admin/contents/:view_id?/:keyword?'
            component={Admin_ContentsResources}
          />
          <PrivateRoute
            exact
            path='/admin/users/:view_id?/:keyword?'
            component={Admin_Users}
          />
          <PrivateRoute
            exact
            path='/admin/audit-trails/:view_id?/:keyword?'
            component={Admin_Auditrails}
          />
          <PrivateRoute
            exact
            path='/admin/roles/:view_id?/:keyword?'
            component={Admin_Roles}
          />
          <PrivateRoute
            exact
            path='/admin/subjects/:view_id?/:keyword?'
            component={Admin_Subjects}
          />
          <PrivateRoute
            exact
            path='/admin/subject/:subject_slug/:view?'
            component={Admin_SubjectDetails}
          />
          <PrivateRoute
            exact
            path='/admin/topic/:topic_slug/:view?'
            component={Admin_TopicDetails}
          />
          <PrivateRoute
            exact
            path='/admin/topics/:view_id?/:keyword?'
            component={Admin_Topics}
          />
          <PrivateRoute
            exact
            path='/admin/organizations/:view_id?/:keyword?'
            component={Admin_Organizations}
          />
          <PrivateRoute
            exact
            path='/admin/organization/:organization_slug/:view?'
            component={Admin_OrganizationDetails}
          />
          <PrivateRoute
            exact
            path='/admin/organization-group/:organization_slug/:view?'
            component={Admin_OrganizationGroupDetails}
          />
          <PrivateRoute
            exact
            path='/myspace/:organization_slug/:view?'
            component={organizationGroupDetails}
          />
          <PrivateRoute
            exact
            path='/instructor/question-manage/:question_slug/:view?'
            component={Instructor_QuestionManage}
          />
          <PrivateRoute
            exact
            path='/instructor/course-manage/:course_slug/:view?/:view2?'
            component={Instructor_CourseManage}
          />
          <PrivateRoute
            exact
            path='/instructor/course-curriculum-content/:course_slug/:lesson_slug/:view?'
            component={Instructor_CourseManageCurriculum}
          />
          <PrivateRoute
            exact
            path='/instructor/practice-manage/:practice_slug/:view?'
            component={Instructor_PracticeManage}
          />
          <PrivateRoute
            exact
            path='/instructor/article-manage/:article_slug/:view?'
            component={Instructor_ArticleManage}
          />
          <PrivateRoute
            exact
            path='/instructor/video-manage/:video_slug/:view?'
            component={Instructor_VideoManage}
          />
          <PrivateRoute
            exact
            path='/instructor/document-manage/:document_slug/:view?'
            component={Instructor_DocumentManage}
          />
          <PrivateRoute
            exact
            path='/instructor/external-resource-manage/:external_resource_slug/:view?'
            component={Instructor_externalResourceManage}
          />
          <PrivateRoute
            exact
            path='/dummy/questions/:view_id?'
            component={Dummy_Question}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </MainWrapper>
  );
}

export default MainRouter;
