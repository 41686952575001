import { config } from '../app.config';
export enum app {
  CHANGE_GRID_VIEW = 'CHANGE_GRID_VIEW',
  CHANGE_LAYOUT_ROLE = 'CHANGE_LAYOUT_ROLE',
  CHANGE_THEME = 'CHANGE_THEME',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  SET_KEYWORD_MAIN_SEARCH = 'SET_KEYWORD_MAIN_SEARCH',
  SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE',
  SET_HOMEPAGE = 'SET_HOMEPAGE',
}

export enum drawer {
  DRAWER_TOOGLE = 'DRAWER_TOOGLE',
  SEC_DRAWER_TOOGLE = 'SEC_DRAWER_TOOGLE',
}

export enum user {
  USER_LOGIN_STATUS = 'USER_LOGIN_STATUS',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_INIT = 'USER_INIT',
  USER_INIT_ASYNC = 'USER_INIT_ASYNC',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
  USER_PERMISSIONS_ASYNC = 'USER_PERMISSIONS_ASYNC',
  GET_USER_SOCKET = 'GET_USER_SOCKET',
  GET_SEARCH_SUGGESTION_LIST = 'GET_SEARCH_SUGGESTION_LIST',
  GET_SEARCH_SUGGESTION_LIST_ASYNC = 'GET_SEARCH_SUGGESTION_LIST_ASYNC',
  GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST',
  GET_NOTIFICATION_LIST_ASYNC = 'GET_NOTIFICATION_LIST_ASYNC',
  GET_MODAL_FOLLOW_USER = 'GET_MODAL_FOLLOW_USER',
  GET_MODAL_JOINING_CLASS = 'GET_MODAL_JOINING_CLASS',
  GET_USER_ORGANIZATIONS = 'GET_USER_ORGANIZATIONS',
  GET_USER_ORGANIZATIONS_ASYNC = 'GET_USER_ORGANIZATIONS_ASYNC',
  SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION',
}
