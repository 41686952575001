export const config = {
  TITLE: 'Xcelearn',
  SUBTITLE:
    'Online Learning & Learning Management System | Learn Anything, On Your Schedule',
  DESCRIPTION:
    "Malaysia's best Personalized Learning for educational approach that aims to customize learning for each student's strengths, needs, skills, and interests. Each student gets a learning plan that's based on what they know and how they learn best.",

  //old url local dev
  CLIENT_ENDPOINT: window.location.origin,
  // SERVER_ENDPOINT: 'http://localhost:3131/api',

  // //new url local dev
  CLIENT_WEB_ENDPOINT: window.location.origin,
  SERVER_MAIN_ENDPOINT: 'http://localhost:3131',
  // SERVER_API_ENDPOINT: 'http://localhost:3131/api',
  // SERVER_SOCKET_ENDPOINT: 'http://localhost:3131/socket',
  // SERVER_AUTH_ENDPOINT: 'http://localhost:3131/auth',

  //old url prod
  // CLIENT_ENDPOINT: 'https://xcelearn.com.my',
  SERVER_ENDPOINT: 'https://xcelearn.com.my/api',

  //new url prod
  // CLIENT_WEB_ENDPOINT: 'https://xcelearn.com.my',
  // SERVER_MAIN_ENDPOINT: 'https://xcelearn.com.my',
  SERVER_API_ENDPOINT: 'https://xcelearn.com.my/api',
  SERVER_SOCKET_ENDPOINT: 'https://xcelearn.com.my/socket',
  SERVER_AUTH_ENDPOINT: 'https://xcelearn.com.my/auth',

  // // //old url dev
  // CLIENT_ENDPOINT: 'https://dev.xcelearn.com.my',
  // SERVER_ENDPOINT: 'https://dev.xcelearn.com.my/api',

  // //new url dev
  // CLIENT_WEB_ENDPOINT: 'https://dev.xcelearn.com.my',
  // SERVER_MAIN_ENDPOINT: 'https://dev.xcelearn.com.my',
  // SERVER_API_ENDPOINT: 'https://dev.xcelearn.com.my/api',
  // SERVER_SOCKET_ENDPOINT: 'https://dev.xcelearn.com.my/socket',
  // SERVER_AUTH_ENDPOINT: 'https://dev.xcelearn.com.my/auth',

  // >>>>>>> 5f95630776fb8827c1d6c71139da067aab72e635
  DEFAULT_LANGUAGE: 'en',
  DEFAULT_THEME: 'light',
  GOOGLE_RECAPTCHA_3_SITE_KEY: '6Lfrvv0bAAAAAN5PhJdKAMmBeWM3ss8c9mvCa8Ra',
  GOOGLE_RECAPTCHA_2_SITE_KEY: '6LceKP4bAAAAAHiwC4dea16cv021oHZpGEShjAC1',
};
